import React, { useContext, useEffect, useState } from 'react';
import { CopyGridAviWrapper, Link } from './CopyRightStyles';
import { GlobalStateContext } from '../../GlobalContextProvider';
 
const CopyGridAvi = () => {

    const state = useContext(GlobalStateContext);
    const open = state.openArticleList;

    const [position, setPosition] = useState('5px');

    useEffect(() => {
        if(!open) {
            setPosition(`5px`);
     
         } else {
     
             if(window.innerWidth > 920) {
                 setPosition(`calc(30% + 5px)`);  
             } else {
                 setPosition(`calc(50% + 5px)`);
             }
             if (window.innerWidth > 2000) {
                 setPosition(`605px`);
             }
         }
    }, [open]);

    return(
        <CopyGridAviWrapper className = 'copy' position = { position }>
            <p>Opracowano przez </p>
            <Link href='https://gridw.pl/' target = '_blank' >Centrum UNEP/GRID-Warszawa </Link>
            <p> oraz </p>
            <Link href='https://www.avinet.no/' target = '_blank' >Asplan Viak Internet</Link>
        </CopyGridAviWrapper>
    )
};

export default CopyGridAvi;
