import React, { useContext, useEffect, useState } from 'react';
import {InformationWrapper, InformationParagraf, InformationTitle, LogosWrapper, LogoPartners, Organizators, StyledLink, StyledClosed } from './InformationCardStyles';
import grid from '../../img/grid.png';
import ambasada from '../../img/ambasada_francji.svg';
import ecoMiasto from '../../img/eco-miasto.svg';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';

const InformationCard = () => {
    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);
    const openInfo = state.openInfo;
    const [display, setDisplay] = useState('none');

    useEffect(()=> {
        openInfo ? setDisplay('block') : setDisplay('none');
    }, [openInfo] );

    const handleCloseInfo = () => {
        dispatch({type: 'OPEN_INFO', payload: !openInfo})
    }

    return (
        <InformationWrapper display = {display}>
            <StyledClosed onClick = {handleCloseInfo} />
            <LogoPartners url = {ecoMiasto} />
            <InformationParagraf>
                ECO-MIASTO to ogólnopolski projekt, którego celem jest popularyzowanie idei zrównoważonego rozwoju miast. Projekt został zainicjowany w 2013 roku i od tego czasu stał się jedną z najbardziej rozpoznawalnych wśród samorządów lokalnych inicjatyw związanych z ochroną środowiska.
            </InformationParagraf>
            <InformationParagraf>
                Konkurs dla miast i międzynarodowa konferencja tworzą przestrzeń do wymiany doświadczeń między samorządami i promocji najlepszych rozwiązań w obliczu aktualnych wyzwań środowiskowych, w dziedzinach takich jak: efektywność energetyczna, gospodarka odpadami, gospodarka wodna, zieleń miejska czy zrównoważona mobilność, co sprzyja naśladowaniu dobrych praktyk.
            </InformationParagraf>
            <InformationParagraf>
                Do udziału w konkursie są zapraszane wszystkie polskie miasta i związki gminne, niezależne od ich rozmiaru, a zgłoszenia konkursowe są rozpatrywane w dwóch kategoriach wielkości miast: powyżej i poniżej 100 tys. mieszkańców.
            </InformationParagraf>
            <InformationParagraf>
                W ramach projektu utworzony został geoportal - interaktywna platforma prezentująca na mapie wyniki wszystkich edycji konkursu Eco-Miasto. Stanowi jedyny w swoim rodzaju zbiór dobrych praktyk polskich miast w dziedzinach dotyczących poprawy jakości życia mieszkańców w dobie walki z kryzysem klimatycznym.
            </InformationParagraf>
            <InformationParagraf>
                Na mapie zostały przedstawione lokalizacje miast, które na przestrzeni wszystkich dotychczasowych edycji zgłosiły do konkursu swoje prośrodowiskowe działania, a także, opisy projektów, które zostały docenione przez jury.
            </InformationParagraf>
            <InformationParagraf>
                ECO-MIASTO jest wspólnym projektem UNEP/GRID-Warszawa oraz Ambasady Francji w Polsce.
            </InformationParagraf>
            <InformationParagraf>
                Więcej informacji na temat konkursu znajdziesz na <StyledLink href="https://www.eco-miasto.pl/" target="_blank" >stronie</StyledLink> projektu.
            </InformationParagraf>
            <Organizators>ORGANIZATORZY: </Organizators>
            <LogosWrapper>
                <LogoPartners url = {grid}/>

                <LogoPartners url = {ambasada}/>
            </LogosWrapper>
        </InformationWrapper>
    )
};

export default InformationCard;