import styled from 'styled-components';

export const StyledWrapper = styled.div`
    height: 100vh;
    position:relative;

    &::after {
        display: ${({black}) => black};
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.36);
        z-index:500;

    }
`;
