import React, { useContext, useEffect, useState } from 'react';
import { CategoryWrapper } from './buttonStyle';
import kat from '../../img/kat.png';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';

const Category = () => {
    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);
    const open = state.openArticleList;
    const [position, setPosition] = useState(`10px`);

    useEffect(() => {
        if(!open) {
           setPosition(`10px`);
    
        } else {
    
            if(window.innerWidth > 920) {
                setPosition(`calc(30% + 10px)`);  
            } else {
                setPosition(`calc(50% + 10px)`);
            }
            if (window.innerWidth > 2000) {
                setPosition(`610px`);
            }
        }
    }, [ open ]);

    const handleOpenCategory = () => {
        dispatch({type: 'OPEN_CATEGORY'});
    }

    return (
        <CategoryWrapper position = { position } url = { kat } onClick = { handleOpenCategory } />
    )
};

export default Category;