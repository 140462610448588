import React from 'react';
import { StyledInput, StyledLabel, StyledCheckbox, ColorCategory } from './SelectedCategoryStyle';
import openEye from '../../img/eye.png';
import closeEye from '../../img/private.png';

const Checkbox = ({ value, name, labelName, handleChooseCategory, url ,categorys}) => {

    return (
        <div>            
            <StyledLabel>
                <StyledInput  type='checkbox' className = 'category' name = { name } value = { value } onChange = { handleChooseCategory } />
                <StyledCheckbox className = 'eye' url = { categorys.indexOf(value) !== -1 ? openEye : closeEye } />
                <ColorCategory url = { url } />
                <p>{labelName}</p>
            </StyledLabel>
        </div>

    )
};

export default Checkbox;
