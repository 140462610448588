import React, { useState, useContext } from 'react';
import { Wrapper,ClearSearchWrapper } from './SerachComponentStyle';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';

const SearchComponent = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const features = state.allFeatures ? state.allfeatures : state.fetchFeatures;
    const [searchValue, setSearchValue] = useState('');
    let featuresSearched = features;
    
    const handleChangeInput = (e) => {
        const value = e.currentTarget.value;
        setSearchValue(value);
        featuresSearched = featuresSearched.filter((element ) => {
            return element.values_.title.toUpperCase().includes(searchValue.toUpperCase());
        })
        if(value === '') {
            featuresSearched = features;
        }
        dispatch({type: 'CHANGE_FEATURES', payload: featuresSearched});
        dispatch({type: 'CHANGE_FILTER_EDITION', payload: 'all'});
        dispatch({type: 'CHANGE_FILTER_SIZE', payload: 'all'});
    }

    const handleClear = () => {
        setSearchValue('');
        featuresSearched = features;
        dispatch({type: 'CHANGE_FEATURES', payload: featuresSearched});
        dispatch({type: 'CHANGE_FILTER_EDITION', payload: 'all'});
        dispatch({type: 'CHANGE_FILTER_SIZE', payload: 'all'});
    }

    return (
        <Wrapper>
            <input type='text' onChange = { handleChangeInput } value = { searchValue } placeholder="wyszukaj miasto..." />
            <ClearSearchWrapper display = { searchValue === '' ? 'none' : 'flex' } onClick = { handleClear } >x</ClearSearchWrapper>
        </Wrapper>
    )
};

export default SearchComponent;
