import React, { useContext, useEffect, useState } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';
import { CategoryList, CategoryWrapper, StyledLabel, CateogryTitle, Title, StyledInput, StyledCheckbox, CloseButton } from './SelectedCategoryStyle';
import openEye from '../../img/eye.png';
import closeEye from '../../img/private.png';
import Checkbox from './Checkbox';
import uuid from '../../dane/uuid';

const SelectCategory = () => {
    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);
    const features = state.fetchFeatures;
    const openCategory = state.openCategory;
    const open = state.openArticleList;
    const [ all, setAll ] = useState(true);
    const [selectedCategorys, setSelectedCategorys] = useState(['energia-nagrodzony', 'gospodarka odpadami-nagrodzony', 'gospodarka wodna-nagrodzony', 'mobilność zrównoważona-nagrodzony', 'zieleń miejska-nagrodzony', 'energia-wyroznienie', 'gospodarka odpadami-wyroznienie', 'gospodarka wodna-wyroznienie', 'mobilność zrównoważona-wyroznienie', 'zieleń miejska-wyroznienie', 'energia-uczestnik', 'gospodarka odpadami-uczestnik', 'gospodarka wodna-uczestnik', 'mobilność zrównoważona-uczestnik', 'zieleń miejska-uczestnik']);
    const [position, setPosition] = useState(`50px`);
    const [eye, setEye ] = useState(openEye);
    const citySize = state.filterSize;
    const edition = state.filterEdition;
    const search = state.search

    const findFeatures = (categorys, feauters) => {
        
        const categoryFeauter = [];
        categorys.map(category => {
            feauters.map( feauter => {
                const catFeauter = feauter.values_.kategoria;
                if(catFeauter === category) {
                    categoryFeauter.push(feauter);
                }
                return null;
            })
            return null;
        })
        return categoryFeauter;
    }

    useEffect(() => {
        
        const featuresList = [];
            if(citySize === 'all') {
                if(!all) {
                    features.forEach(element => {
                        const editionFeature = element.values_.edycja;
                        if(edition !== 'all') {
                            if (editionFeature == edition) {
                                featuresList.push(element);
                            }
                        } else {
                            featuresList.push(element);
                        }
                        
                    })
                    dispatch({type: 'CHANGE_FEATURES', payload: findFeatures(selectedCategorys, featuresList )});
    
                } else {
                    features.forEach(element => {
                        const editionFeature = element.values_.edycja;
                        if( edition !== 'all') {
                            if (editionFeature == edition) {
                                featuresList.push(element);
                            }
                        } else {
                            featuresList.push(element);
                        }
                       
                    })
                    dispatch({type: 'CHANGE_FEATURES', payload: featuresList});
                }
            } else if (citySize === 'big') {
                if(!all) {
                    const filteredFeatures = findFeatures(selectedCategorys, features );
                    filteredFeatures.forEach(element => {
                        if (element.values_.typ) {
                            if(edition !== 'all') {
                                if(element.values_.edycja == edition) {
                                    featuresList.push(element);
                                }
                            } else {
                                featuresList.push(element);
                                console.log(element)
                            }
                        }
                    })
                } else {
                    const filteredFeatures = state.fetchFeatures
                    filteredFeatures.forEach(element => {
                        if (element.values_.typ) {
                            if(edition !== 'all') {
                                if (element.values_.edycja == edition) {
                                    featuresList.push(element);
                                }
                            } else {
                                featuresList.push(element);
                            }
                            
                        }
                    })
                } 
                dispatch({type: 'CHANGE_FEATURES', payload: featuresList});
            } else if (citySize === 'small') {
                if(!all) {
                    const filteredFeatures = findFeatures(selectedCategorys, features );
                    filteredFeatures.forEach(element => {
                        if (!element.values_.typ) {
                            if(edition !== 'all'){
                                if (element.values_.edycja == edition) {
                                    featuresList.push(element);
                                }
                            } else {
                                featuresList.push(element);
                            }
                            
                        }
                    })
                } else {
                    const filteredFeatures = state.fetchFeatures
                    filteredFeatures.forEach(element => {
                        if (!element.values_.typ) {
                            if(edition !== 'all') {
                                if (element.values_.edycja == edition) {
                                    featuresList.push(element);
                                }
                            } else {
                                featuresList.push(element);
                            }
                            
                        }
                    })
                }

                dispatch({type: 'CHANGE_FEATURES', payload: featuresList});
            } 
        
    }, [ selectedCategorys, citySize, edition ]);

    useEffect(() => {
        if(!open) {
           setPosition(`50px`);
    
        } else {
    
            if(window.innerWidth > 920) {
                setPosition(`calc(30% + 50px)`);  
            } else {
                setPosition(`calc(50% + 50px)`);
            }
            if (window.innerWidth > 2000) {
                setPosition(`660px`);
            }
        }
    }, [ open ]);

    useEffect(() => {
        if (selectedCategorys.length === 15) {
            setAll(true);
            setEye(all ? openEye: closeEye);
        } else {
            setAll(false);
            setEye(all ? openEye: closeEye);
        }

    }, [selectedCategorys, all])

    const handleChooseCategory = (e) => {   

        const category = e.currentTarget.value;
        const checked = e.currentTarget.checked;

        if(category === 'wszystkie') {
            setAll(!all);
            setEye( all ? openEye : closeEye)
            if(checked) {
                setSelectedCategorys(['energia-nagrodzony', 'gospodarka odpadami-nagrodzony', 'gospodarka wodna-nagrodzony', 'mobilność zrównoważona-nagrodzony', 'zieleń miejska-nagrodzony', 'energia-wyroznienie', 'gospodarka odpadami-wyroznienie', 'gospodarka wodna-wyroznienie', 'mobilność zrównoważona-wyroznienie', 'zieleń miejska-wyroznienie', 'energia-uczestnik', 'gospodarka odpadami-uczestnik', 'gospodarka wodna-uczestnik', 'mobilność zrównoważona-uczestnik', 'zieleń miejska-uczestnik'])
            } else {
                setSelectedCategorys([])
            }
           
        } else {
            if(selectedCategorys.indexOf(category) === -1) {
                setSelectedCategorys([ ...selectedCategorys, category]);
            } else {
                setSelectedCategorys(
                    selectedCategorys.filter( item => {
                        return item !== category;
                    }
                ))
            }
        }
    }

    const handleCloseCategory = () => {
        dispatch({type: 'OPEN_CATEGORY'});
    }

    return (
        <CategoryWrapper open = { openCategory ? 'flex' : 'none' } position = { position } >
            <CateogryTitle>
                <Title>Kategorie konkursowe</Title>
                <CloseButton onClick = { handleCloseCategory } />
            </CateogryTitle>
            <CategoryList>

                    <div>
                        <StyledLabel>
                            <StyledInput type='checkbox' onChange = { handleChooseCategory } value = 'wszystkie' checked = { all }/>
                            <StyledCheckbox url = { eye } />
                            wszystkie
                        </StyledLabel>
                    </div>
                
                    {
                        uuid.map((element, id) =>  {
                            return (
                                <Checkbox key = { id } handleChooseCategory = { handleChooseCategory } name = { element.name } value = { element.category } labelName = { element.name } allChecked = { all } url = {element.icon} categorys = { selectedCategorys } />
                            )
                        })
                    }
                    
            </CategoryList>
        </CategoryWrapper>
    )
};

export default SelectCategory;
