import React, { useContext, useState, useEffect } from 'react';
import {InfoWrapper } from './buttonStyle';
import info from '../../img/info.png';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';


const InfoButton = () => {
    const state = useContext(GlobalStateContext);
    const infoOpen = state.openInfo
    const dispatche = useContext(GlobalDispatchContext);
    const open = state.openArticleList;
    const [position, setPosition] = useState('10px')

    useEffect(()=> {
        if(!open) {
            setPosition(`10px`);
     
         } else {
     
             if(window.innerWidth > 920) {
                 setPosition(`calc(30% + 10px)`);  
             } else {
                 setPosition(`calc(50% + 10px)`);
             }
             if (window.innerWidth > 2000) {
                 setPosition(`610px`);
             }
         }
    }, [open])
    const handleOpenInfo = () => {
        dispatche({type: 'OPEN_INFO', payload: !infoOpen })
    }

    return (
        <InfoWrapper url = { info } position = { position } onClick = { handleOpenInfo } />  
    );
};

export default InfoButton;