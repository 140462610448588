import styled from 'styled-components';
import { CloseButton } from '../SelectCategory/SelectedCategoryStyle';


export const InformationWrapper = styled.div`
    display: ${({display})=> display};
    position: fixed;
    width: 700px;
    top: 50px;
    left: calc(50vw - 350px);
    z-index: 1000;
    background-color: ${({theme}) => theme.colors.white};
    padding: 20px;
    border-radius: 4px;
    height: 80vh;
    overflow: auto;

    @media ${({theme})=>theme.media.small} {
        width: 100%;
        height: 100%;
        top:0;
        left:0;
    }

    @media only screen and (min-height: 1000px) and (min-width: 1400px) {
        height: inherit
    }
`;

export const InformationParagraf = styled.p`
    color: ${({theme}) => theme.colors.main};
    margin: 10px 0;
    font-size: 14px;
`;

export const InformationTitle = styled.h2`
    color: ${({theme})=> theme.colors.colorMain};
`;

export const LogosWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 10px;
`;

export const StyledLink = styled.a`
    color: ${({theme})=> theme.colors.colorMain};
    text-decoration: none;
    font-weight: 700;
`;

export const LogoPartners = styled.div`
    background-image: ${ ({ url }) => `url('${ url }')`};
    width: 200px;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
`;

export const Organizators = styled.p`
    font-weight: 700;
    color: ${({theme})=> theme.colors.colorMain};
    text-align: center;
    margin: 15px 0;
`

export const StyledClosed = styled(CloseButton)`
    position: fixed;
    right: calc(50% - 345px);
    width: 30px;
    height: 30px;

    ::before, ::after {
        height: 25px;
        width: 2px;
    }

    @media ${({theme}) => theme.media.small} {
        right: 5px;
    }

`;
