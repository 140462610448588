import React, { useContext, useEffect } from 'react';
import { OpenButtonWrapper, Div, CloseButtonWrapperMobile, DivClose } from './buttonStyle';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';
import img from '../../img/arrow.png';
import cancel from '../../img/cancel.png';

const ArticleListOpenButton = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const open = state.openArticleList;
   
    useEffect(() => {
        const img = document.querySelector('.buttonOpen');
        const button = document.querySelector('.button');

        if(!open) {
            img.style.transform = 'rotate(180deg)'; 
            button.style.display = 'flex';
            button.style.left = '0';
    
            if(window.innerWidth < 768) {
                img.style.display = 'flex';
            }
    
        } else {
            img.style.transform = `none`;
    
            if(window.innerWidth > 920) {
                button.style.left = `30%`;  
            } else {
                button.style.left = `50%`;
            }
    
            if (window.innerWidth < 768) {
                button.style.display = 'none';
            }
            if (window.innerWidth > 2000) {
                button.style.left = `600px`;
            }
        }

    }, [ open ]);
    
    const handleOpenArticleList = () => {
        dispatch({type: 'OPEN_ARTICLELIST', payload: open ? false : true});
    }
    
    return (
        <> 
            <OpenButtonWrapper className = 'button' >
                <Div url = { img } onClick = { handleOpenArticleList } className = 'buttonOpen'/>
            </OpenButtonWrapper>
            <CloseButtonWrapperMobile display = { open ? 'flex' : 'none' }>
                <DivClose url = { cancel } onClick = { handleOpenArticleList } />
            </CloseButtonWrapperMobile>
        </>
    )
};

export default ArticleListOpenButton;
