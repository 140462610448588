import { Style, Icon } from 'ol/style';
import uuid from '../dane/uuid';

const useStyle = () => {

    const getIcon = (kategoria, status) => {
      for (let i = 0; i < uuid.length; i++) {
        if (uuid[i].status === status && uuid[i].category === kategoria) {
          return uuid[i].icon;
        }
      }
    }

    const getSize = (typ) => {
      if(typ) {
        return 0.4;
      } else {
        return 0.3;
      }
    }

    const getZIndex = (status) => {
      if(status === 'zwycięstwo') {
        return 100;
      }
      if(status === 'wyróżnienie') {
        return 80;
      }
      if(status === 'uczestnictwo') {
        return 10;
      }
      return 100000;
    }

    const featureStyle = ( feature ) => {
      return new Style({
        image: new Icon({
          src: getIcon(feature.values_.kategoria, feature.values_.status),
          scale: getSize(feature.values_.typ),
        }),
        zIndex: getZIndex(feature.values_.status)
      })    
    }
    return { featureStyle }
}

export default useStyle;
