import React, { useContext, useState, useEffect } from 'react';
import { GlobalStateContext } from '../../GlobalContextProvider';
import { StyledWrapper, SortingWrapper, StyledWrapperEdtionSize } from './StyledArticleList';
import ArticleCard from '../ArticleCard/ArticleCard';
import Article from '../ArticleComponent/Article';
import ArticleListOpen from '../buttons/ArticleListOpen';
import SelectSizeCity from '../SelectSizeCity/SelectSizeCity';
import SelectEdition from '../SelecetEdition/SelectEdition';
import SearchComponent from '../SearchComponent/SearchComponent';

const ArticleList = () => {
    const state = useContext(GlobalStateContext);
    const features = state.allfeatures;
    const open = state.openArticleList;
    const openArticle = state.singleArticle;
    const singleArticle = state.singleArticle;
    const [padding, setPadiing] = useState('100px');

    useEffect(() => {
        if(window.innerWidth < 768) {
            setPadiing('103px')
        }
    }, []);

    return (
        <>
            <StyledWrapper open = { open ? `flex` : `none` } padding-top = { singleArticle ? '0px' : padding }>
                
                <SortingWrapper open = {singleArticle ? `none` : `flex`}>
                    <SearchComponent />
                    <StyledWrapperEdtionSize>
                        <SelectEdition />
                        <SelectSizeCity />
                    </StyledWrapperEdtionSize>
                </SortingWrapper>
                {   
                    openArticle ?
                    <Article /> :
                    features.map((feature, i) => {
                        return <ArticleCard key = { i } title = { feature.values_.title } opis = { feature.values_.content } point = { feature.values_.geometry.flatCoordinates } foto = { feature.values_.foto } link = { feature.values_.link } feature = { feature } wartosc = { feature.values_.wartosc} category = { feature.values_.category } grafika = {feature.values_.grafika} nagroda = {feature.values_.nagroda} edycja = {feature.values_.edycja} wielkosc = {feature.values_.status} /> })
                }

            </StyledWrapper> 
            <ArticleListOpen /> 
        </>
    )
}

export default ArticleList;
