import React, { useContext, useState, useEffect } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';
import { SelectWrapper, SelectData } from './SelectSizeCityStyle';

const SelectSizeCity = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const size = state.filterSize;
    const [value, setSize ] = useState('all');

    const handleChangeCitySize = (e) => {
        const currentValue = e.currentTarget.value;
        dispatch({ type: "CHANGE_FILTER_SIZE", payload: currentValue });
    }

    useEffect(() => {
        setSize(size);
    }, [size])

    return (
        <SelectWrapper>
            <SelectData onChange = { handleChangeCitySize } value = { value }>
                <option value="all">filtruj po wielkości miasta</option>
                <option value='all'>wszyscy</option>
                <option value = "big">&gt; 100 tys. kieszkańców</option>
                <option value = "small">&lt; 100 tys. mieszkańców</option>
            </SelectData>
        </SelectWrapper>
    )
};

export default SelectSizeCity;
