import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    border-radius: 5px;
    width: 32px;
    height: 32px;
    background-size: 23px;
    background-repeat: no-repeat;
    background-color: ${({theme}) => theme.colors.colorMain};
    background-image: ${ ({ url }) => `url('${ url }')`};
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 10px;
    background-position: center;
`;

export const GlobeWrapper = styled(ButtonWrapper)`
    right: 26px;
    top: unset;
    left: unset;
    bottom: 58px;

    @media ${({theme})=>theme.media.small} {
        bottom: 35px;
    }
 `;

export const CategoryWrapper = styled(ButtonWrapper)`
    position: absolute;
    top: 10px;
    left: ${(props) => props.position};
`;

export const InfoWrapper = styled(CategoryWrapper)`
    top: 45px;
`;

export const OpenButtonWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.colorMain };
    background-position: 6px center;
    width: 30px;
    height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    top: calc(50vh - 15px);
    cursor: pointer;    
`; 

export const CloseButtonWrapperMobile = styled.div`
    display: ${ ({ display }) => display };
    background-size: contain;
    background-color: ${({ theme }) => theme.colors.colorMain };
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    left: inherit !important;
    right: 20px;
    position: absolute;
    top: 90px;
    cursor: pointer;
    z-index: 200;

    @media ${({ theme }) => theme.media.xl} {
        display: none;
    }
`;

export const Div = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: ${ ({ url }) => `url('${ url }')`};
    transform: rotate(-180deg);
    background-size: 15px !important;
    background-position: 6px center;
    background-size: contain;
    background-repeat: no-repeat;
`;

export const DivClose = styled(Div)`
    background-size: 25px !important;
`;