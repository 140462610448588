import React from 'react';
import { Link, OpenStreetMapWrapper } from './CopyRightStyles';

const OpenStreetMap = () => {
    return (
        <OpenStreetMapWrapper>
            <Link href = 'https://www.openstreetmap.org/copyright' target = '_blank'> &copy; OpenStreetMap</Link>  
        </OpenStreetMapWrapper>
    )
};

export default OpenStreetMap;

