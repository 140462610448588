import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 0 10px;
    text-align: center;
    input {
        color: #686767;
        border: 1px solid #686767;
        border-radius: 4px;
        cursor: pointer;
        padding-left: 5px;
        width: 90%;
        height: 30px;
    }
`;

export const ClearSearchWrapper = styled.div`
    display: ${({display}) => display};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 23px;
    right: 6%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #ccc;

    @media ${({theme}) => theme.media.small} {
        right: 15%;
    }
`;