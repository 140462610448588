import { useEffect, useState } from "react";
import axios from 'axios';
import { olUtils } from '@avinet/react-openlayers';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import uuid from '../dane/uuid';


const processRecord = (record, category) => {
  const feature = olUtils.createFeatureFromWkt(record.geom_wkt);
  return  {
    olfeature: feature,
    id: record.id,
    komentarz: record.komentarze_uwagi,
    title: record.miasto,
    content: record.opis,
    foto: record.zdjecie,
    link: record.link,
    typ: record.typ_miasta,
    status: record.status,
    category: record.kateg_opis,
    nagroda: record.nagroda,
    kategoria: category,
    edycja: record.edycja,
    ...record,
  };
}

let features = [];

const useFeatures = () => {
  
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
    
  useEffect(() => {
    uuid.forEach( element => {
      axios.post("https://gridw.geopanel.eu/eco-miasto/WebServices/client/DataView.asmx/ReadAny", {
      headers: {
        'Content-Type' : 'application/json; charser=UTF-8'
      },
      request: {
        "theme_uuid": element.uuid,
        "columns": ["id", "miasto", "typ_miasta", "kateg_opis", "status", "edycja", "link", "lb_mieszk", "opis", "kat_wielkosci", "zdjecie", "nagroda"],
        "excludeGeometry":false,
        "sortByGeomColumn":"geom",
        "sortByGeomGeometry":"POINT(2315000 6870000)",
        "start":0,
        "limit":100,
        "filter":{
        "filterColumns":[],
        "defaultFilter":[],
        "sortColumns":[],
        "distinctColumns":[]},
        "extraParams":[],
        "srid": "900913"
      }
    }
    )
    .then(res => {
      const data = res.data.d.records;
      const group = [];
      const category = element.category; 

      data.forEach((element, id )=> {
        group.push(processRecord(element, category));  
        const newFeature = processRecord(element, category);
        features.push(new Feature({
          geometry: new Point(newFeature.olfeature.values_.geometry.flatCoordinates),
          title: newFeature.miasto,
          content: newFeature.opis,
          category: newFeature.kateg_opis,
          kategoria: category,
          foto: newFeature.zdjecie,
          link: newFeature.link,
          typ: newFeature.typ_miasta,
          status: newFeature.status,
          nagroda: newFeature.nagroda,
          edycja: newFeature.edycja,
        }))
      })
      setArticles(articles.concat(group))
      setLoading(true);
    })    
    })
    
  }, []);

  

  return {features, loading};
}

export default useFeatures;
