import React, { useContext } from 'react';
import { GlobalStateContext, GlobalDispatchContext } from '../../GlobalContextProvider';
import { ArticleWrapper, ArticleTitle, ArticleContent, ArticleFoto, StyledLink, ButttonBack, ArticleFotoWrapper, HeaderWrapper, ZoomOnMap, ArticleContentAward } from './StyledArticle';
import zoomIn from '../../img/zoom.png';

const Article = ( ) => {
    const state = useContext(GlobalStateContext);
    const content = state.articleContent;
    const opis = content.content;
    const link = content.link;
    const nagroda = content.nagroda;
    const dispatch = useContext(GlobalDispatchContext);

    const handleBackButton = () => {
        dispatch({ type: 'OPEN_ARTICLE', payload: false });
        dispatch({type: 'SET_ACTIVE_FEATURE_ID', payload: '0'})
    }

    const handleZoomOnMap = () => {
        dispatch({ type: 'CENTER_CHANGE', payload: content.point});
        dispatch({ type: 'ZOOM_CHANGE', payload: 15 });
    }

    return (
        <ArticleWrapper>
            <HeaderWrapper>
                <ZoomOnMap onClick = { handleZoomOnMap } url = { zoomIn }>Przybiliż na mapie</ZoomOnMap>
                <ButttonBack onClick = { handleBackButton } />
            </HeaderWrapper>
           { content.foto === null || content.foto === '' ? '' : <ArticleFotoWrapper>
                <ArticleFoto url = { content.foto } />
            </ArticleFotoWrapper>}
            <ArticleTitle>{ content.title }</ArticleTitle>
            { nagroda === undefined ? '' : <ArticleContentAward dangerouslySetInnerHTML = {{ __html: nagroda}} />}
            <ArticleContentAward>
                <strong>Edycja konkursu: </strong>{content.edycja}
            </ArticleContentAward>
            <ArticleContentAward><strong>Kategoria konkursowa: </strong>{content.category}</ArticleContentAward>
            <ArticleContentAward><strong>Kategoria wielkości miasta: </strong>{content.wielkosc ? "powyżej 100 tys. mieszkańców" : "poniżej 100 tys. mieszkańców"}</ArticleContentAward>
            <ArticleContentAward><strong>Opis</strong></ArticleContentAward>
            <ArticleContent dangerouslySetInnerHTML = {{ __html: opis}} />
            <StyledLink dangerouslySetInnerHTML = {{ __html: link}} />
        </ArticleWrapper>
    )
};

export default Article;
