import styled from 'styled-components';
import { CloseButton } from '../SelectCategory/SelectedCategoryStyle';

export const ArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
`;

export const ArticleTitle = styled.h2`
    color: ${ ({ theme }) => theme.colors.articleTitle };
    margin: 10px 0;
    font-size: 24px;
    padding: 0 35px;
`;

export const ArticleContentAward = styled.p`
    position: relative;
    color: ${({theme}) => theme.colors.main};
    margin: 15px 0 5px;
    font-size: 14px;
    padding: 0 35px;
`;

export const ArticleContent = styled(ArticleContentAward)`
    :after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.grey};
        bottom: -10px;
        left: 0;
    }
`;

export const ArticleFoto = styled.div`
    background-image: ${ ({ url }) => `url(https://gridw.geopanel.eu/eco-miasto/WebServices/generic/Media.asmx/Download?thumbnail_size=small&uuid=${ url })`};
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
    background-position: center;
    margin: 5px auto;
`;

export const ArticleFotoWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey };
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey };
    width: 100%;
    margin: 5px 0;
`;

export const StyledLink = styled.p`
    text-decoration: none;
    padding: 10px 0 2px;
    position: relative;
    margin: 15px auto;

    ::after {
        width: 110px;
        height: 3px;
        position: absolute;
        content: '';
        background-color: ${({ color }) => color };
        bottom:0;
        left: 0;
    }

    a {
        font-size: 12px;
        letter-spacing: 0.5px;
        cursor: pointer;
        color: ${({theme}) => theme.colors.colorMain}
    }
`

export const ButttonBack = styled(CloseButton)`
    width: 30px;
    height: 30px;

    ::before, ::after {
        height: 25px;
        width: 2px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
`;

export const ZoomOnMap = styled.div`
    color: ${({ theme }) => theme.colors.main};
    font-size: 12px;
    cursor: pointer;
    position: relative;
    margin-left: 50px;

    :before {
        position: absolute;
        content: '';
        width: 15px;
        height: 15px;
        background-image: ${ ({ url }) => `url('${ url }')`};
        background-size: contain;
        background-repeat: no-repeat;
        left: -25px;
    }
`;

export const ValueTree = styled.p`
    color: ${({theme})=> theme.colors.main};
    font-size: 14px;
`;