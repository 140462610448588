import energiaWygrane from '../img/static/energia_zwyciestwo.png';
import energiaWyroznienie from '../img/static/energia_wyroznienie.png';
import energiaUczestnik from '../img/static/energia_uczestnictwo.png';
import odpadyWygrane from '../img/static/gospodarkaOdpadami_zwyciestwo.png';
import odpadyWyroznienie from '../img/static/gospodarkaOdpadami_wyroznienie.png';
import odpadyUczestnik from '../img/static/gospodarkaOdpadami_uczestnictwo.png';
import wodaWygrane from '../img/static/gospodarkaWodna_zwyciestwo.png';
import wodaWyroznienie from '../img/static/gospodarkaWodna_wyroznienie.png';
import wodaUczestnik from '../img/static/gospodarkaWodna_uczestnictwo.png';
import mobilnoscWygrane from '../img/static/mobilnosc_zwyciestwo.png';
import mobilnoscWyroznienie from '../img/static/mobilnosc_wyroznienie.png';
import mobilnoscUczestnik from '../img/static/mobilnosc_uczestnictwo.png';
import zielenWygrane from '../img/static/zielenMiejska_zwyciestwo.png';
import zielenWyroznienie from '../img/static/zielenMiejska_wyroznienie.png';
import zielenUczestnik from '../img/static/zielenMiejska_uczestnictwo.png';

const uuid = [
    {
        uuid: "f55e6298-9966-42e9-b3d1-b4945a005877",
        category: 'energia-nagrodzony',
        name: 'Energia - nagrodzony',
        icon: energiaWygrane,
        status: "zwycięstwo"
    },
    {
        uuid: "aa678632-6ed6-4965-bef3-c81b9fd1f6a0",
        category: 'gospodarka odpadami-nagrodzony',
        name: 'Gospodarka o obiegu zamkniętym - nagrodzony',
        icon: odpadyWygrane,
        status: "zwycięstwo"
    },
    {
        uuid: "718317b8-2d7c-4458-b5ce-d9c1fb1c0888",
        category: 'gospodarka wodna-nagrodzony',
        name: 'Gospodarka wodna - nagrodzony',
        icon: wodaWygrane,
        status: "zwycięstwo"
    },
    {
        uuid: "0a57a9e6-32c0-4f62-ba00-6a8b10da970c",
        category: 'mobilność zrównoważona-nagrodzony',
        name: 'Zrównoważona mobilność - nagrodzony',
        icon: mobilnoscWygrane,
        status: "zwycięstwo"
    },
    {
        uuid: "3a099efa-906e-487c-ba49-6dc04e492d4c",
        category: 'zieleń miejska-nagrodzony',
        name: 'Zieleń miejska - nagrodzony',
        icon: zielenWygrane,
        status: "zwycięstwo"
    },
    {
        uuid: "7ea96191-2dd8-46ee-9043-bcda21f0f512",
        category: 'energia-wyroznienie',
        name: 'Energia - wyróżnienie',
        icon: energiaWyroznienie,
        status: "wyróżnienie"
    },
    {
        uuid: "5389aca0-78f5-46f7-9b9f-ad197169ff65",
        category: 'gospodarka odpadami-wyroznienie',
        name: 'Gospodarka o obiegu zamkniętym - wyróżnienie',
        icon: odpadyWyroznienie,
        status: "wyróżnienie"
    },
    {
        uuid: "3fc541d2-83a2-4662-9d98-f44089311d1d",
        category: 'gospodarka wodna-wyroznienie',
        name: 'Gospodarka wodna - wyróżnienie',
        icon: wodaWyroznienie,
        status: "wyróżnienie"
    },
    {
        uuid: "96310fa8-3fe2-40c3-a778-ed03100d91b1",
        category: 'mobilność zrównoważona-wyroznienie',
        name: 'Zrównoważona mobilność - wyróżnienie',
        icon: mobilnoscWyroznienie,
        status: "wyróżnienie"
    },
    {
        uuid: "45e60a14-4687-4b12-b433-5ce793c2fbbf",
        category: 'zieleń miejska-wyroznienie',
        name: 'Zieleń miejska - wyróżnienie',
        icon: zielenWyroznienie,
        status: "wyróżnienie"
    },
    {
        uuid: "e93fc25e-50aa-4d1b-b996-e0eefeddde96",
        category: 'energia-uczestnik',
        name: 'Energia - uczestnik',
        icon: energiaUczestnik,
        status: "uczestnictwo"
    },
    {
        uuid: "de84986e-1a91-4900-99f1-369999dcb465",
        category: 'gospodarka odpadami-uczestnik',
        name: 'Gospodarka o obiegu zamkniętym - uczestnik',
        icon: odpadyUczestnik,
        status: "uczestnictwo"
    },
    {
        uuid: "fa6f9d55-0bec-4280-a45a-312e87b58d5d",
        category: 'gospodarka wodna-uczestnik',
        name: 'Gospodarka wodna - uczestnik',
        icon: wodaUczestnik,
        status: "uczestnictwo"
    },
    {
        uuid: "1ca618c5-cbff-4c86-8001-eee6156599c0",
        category: 'mobilność zrównoważona-uczestnik',
        name: 'Zrównoważona mobilność - uczestnik',
        icon: mobilnoscUczestnik,
        status: "uczestnictwo"
    },
    {
        uuid: "0fea2d22-e149-46f4-9f35-e5c741b2be0f",
        category: 'zieleń miejska-uczestnik',
        name: 'Zieleń miejska - uczestnik',
        icon: zielenUczestnik,
        status: "uczestnictwo"
    }
];

export default uuid;
