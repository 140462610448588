import React from 'react';
import GlobalContextProvider from './GlobalContextProvider';
import MapComponent from './components/MapComponent/MapComponent';
import GlobalStyle from './assets/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from './assets/theme';
import './assets/style.scss';
import Globe from './components/buttons/Globe';
import ArticleList from './components/ArticleList/ArticleList';
import OpenStreetMap from './components/copyRight/OpenStreetMap';
import Category from './components/buttons/Category';
import SelectCategory from './components/SelectCategory/SelectCategory';
import InformationCard from './components/InforamtionCard/InfromationCard';
import Info from './components/buttons/Info';


const App = () => {
  return (
    <GlobalContextProvider>
      <GlobalStyle />
      <ThemeProvider theme = { theme }>
      <MapComponent />
      
      <SelectCategory />
      <Info />
      <Globe />
      <ArticleList />
      <OpenStreetMap />
      <Category />
      <InformationCard />
      </ThemeProvider>
    </GlobalContextProvider>
  );
}

export default App;
