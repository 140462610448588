import styled from 'styled-components';

export const SelectWrapper = styled.div``;

export const SelectData = styled.select`
    color: #686767;
    border-color: #686767;
    align-self: flex-end;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
`;