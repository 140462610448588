import styled from 'styled-components';

export const StyledWrapper = styled.div`
    padding-top: ${(padding) => padding};
    display: ${({ open }) => open }; 
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white };
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-x: hidden;
    width: 30vw;
    max-width:600px;
    z-index: 200;

    @media ${({ theme }) => theme.media.medium } {
        width: 50vw;
    };

    @media ${({ theme }) => theme.media.small } {
        width: 100vw;
    }    
`;

export const SortingWrapper = styled.div`
    position: fixed;
    flex-direction: column;
    width: 30vw;
    top:0;
    left:0;
    padding: 20px 0;
    display: ${({ open }) => open };    
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.grey};
    max-width:600px;
    p {
        font-size: 14px;
    }

    @media ${({ theme }) => theme.media.medium } {
        width: 50vw;
    }
    @media ${({ theme }) => theme.media.small } {
        width: 100vw;
    }    
`;

export const StyledWrapperEdtionSize = styled.div`
    display: flex;
    margin: 0 auto;
    width: 90%;
    justify-content: space-between;
`;

export const SelectData = styled.select`
    width: 120px;
    color: #686767;
    border-color: #686767;
    align-self: flex-end;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    
`;

